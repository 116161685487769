@import '../../../variables';

.wrapper {
  margin: 0;
}

.notTitle {
  margin-top: 0;
}

.question {
  margin-top: 25px;
}

.centeredContentContainer {
  display: flex;
  justify-content: center;
  align-items: center;
}

.itemContent {
  margin-bottom: 16px;
  @include border-floating;
  border-color: transparent;
  box-shadow: 0px 0.333333px 16px rgba(0, 0, 0, 0.01),
    0px 1.25px 5px rgba(0, 0, 0, 0.06) !important;
  border-radius: 4px !important;
  background: white;
}
.editIconContainer {
  position: absolute;
  bottom: 38px;
  right: 10px;
  height: 30px;
  width: 30px;
  text-align: center;
  color: #fff;
  width: 26px;
  height: 24px;
  background: rgb(0, 0, 0, 0.3);
  border: 1px solid #d9d9d9;
  box-shadow: 0px 2px 0px rgba(0, 0, 0, 0.043);
  border-radius: 2px;
  transition: color 0.3s;
  z-index: 3;
}
.editIconContainer:hover {
  color: #000;
  background-color: #ffffff;
}
.downloadIconContainer {
  position: absolute;
  bottom: 8px;
  right: 10px;
  height: 30px;
  width: 30px;
  text-align: center;
  border-radius: 50%;
  color: #fff;
  width: 26px;
  height: 24px;
  background: rgb(0, 0, 0, 0.3);
  border: 1px solid #d9d9d9;
  box-shadow: 0px 2px 0px rgba(0, 0, 0, 0.043);
  border-radius: 2px;
  z-index: 3;
}
.downloadIconContainer:hover {
  color: #000;
  background-color: #ffffff;
}
.modal:global.ant-modal {
  width: 100%;
}

.modal :global .ant-modal-close {
  background: rgba(0, 0, 0, 0.45);
}

.modal :global .ant-modal-close-x {
  margin-left: auto;
}

.modal :global .ant-modal-close-x svg {
  fill: var(--color-white);
}

.modal :global .ant-modal-body {
  padding: 0;
}

.modal :global .ant-modal-footer {
  display: none;
}

.dividerText {
  font-size: 12px;
  display: flex;
  align-items: center;
  margin: 16px;
  span {
    margin-right: var(--spacing-l);
  }
}

.noteContentBox {
  font-size: 12px;
  margin-bottom: 16px;
}

.checkboxGroup {
  display: flex;
  flex-direction: column;
}

.checkbox:global.ant-checkbox-wrapper {
  display: flex;
  align-items: center;
  margin-left: 0;
  cursor: default;
}
.checkbox:global.ant-checkbox-wrapper:not(:last-child) {
  margin-bottom: 20px;
}

.checkbox :global .ant-checkbox-inner {
  width: 20px;
  height: 20px;
  border-radius: 50%;
  border-color: var(--color-gray-1);
  background-color: var(--color-gray-1);
}
.checkbox :global .ant-checkbox {
  top: 0;
  cursor: default;
}

.checkbox :global .ant-checkbox-input {
  cursor: default;
}

.checkbox:global.ant-checkbox-wrapper:hover .ant-checkbox-inner,
.checkbox :global .ant-checkbox:hover .ant-checkbox-inner,
.checkbox :global .ant-checkbox-input:focus + .ant-checkbox-inner {
  border-color: transparent;
}

.checkbox :global .ant-checkbox + span {
  font-weight: 500;
  color: var(--color-gray-1);
  padding-left: 10px;
}

.checkbox :global .ant-checkbox-checked .ant-checkbox-inner {
  border-color: var(--color-black-5);
  background-color: var(--color-black-5);
}

.checkbox:global.ant-checkbox-wrapper:hover
  .ant-checkbox-inner.ant-checkbox-checked,
.checkbox:global.ant-checkbox:hover .ant-checkbox-inner.ant-checkbox-checked,
.checkbox:global.ant-checkbox-input:focus
  + .ant-checkbox-inner.ant-checkbox-checked {
  border-color: var(--color-black-5);
}

.checkbox :global .ant-checkbox-checked + span {
  color: var(--color-black-5);
}

.checkbox :global .ant-checkbox-checked::after {
  border-color: transparent;
}

.checkbox :global .ant-checkbox-checked .ant-checkbox-inner::after {
  // the checkmark looked like as in design
  height: 13px;
  top: 47%;
}

.answersContent {
  padding: 16px;
}

.emptySubmissions {
  background: white;
  padding: 16px;
  margin: 0;
  @include border-floating;
  border-color: transparent;
  box-shadow: 0px 0.333333px 16px rgba(0, 0, 0, 0.01),
    0px 1.25px 5px rgba(0, 0, 0, 0.06);
  border-radius: 4px;
}

.status {
  color: white;
  padding: 0 var(--spacing-l);
  border-radius: 11px;
  border: 0;
}

.answerStatusTag {
  flex: 1 0 auto;
  display: flex;
  justify-content: flex-end;
}
.imageCard {
  position: relative;
  width: 25%;
  padding: 2px !important;
  text-align: center;
  cursor: pointer;
  box-shadow: none !important;
  transition: all 0.2s ease-in 0s;
  background-color: white;
  border-radius: 4px !important;
}
.hoverableOverlay {
  position: absolute !important;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  cursor: pointer;
  display: none;
  z-index: 2;
  padding: 4px 4px 0;
  border-radius: 4px;
}
.imageCard:hover {
  .hoverableOverlay {
    display: block;
    background: rgba(0, 0, 0, 0.215);
  }
  .editIconContainer {
    color: #000;
    background-color: #ffffff !important;
  }
  .downloadIconContainer {
    color: #000;
    background-color: #ffffff !important;
  }
}
