.questions {
  display: flex;
  flex-direction: column;
  gap: 12px;
}

.contentLayout {
  height: 100%;
  background: var(--background-4);
}

.headerContent {
  display: flex;
  flex-direction: column;
  border-bottom: 1px solid var(--color-gray-0);
  box-shadow: var(--box-shadow-container);
}

.header {
  padding: 8px 32px;
  width: 100%;
}

.headerSubTitle {
  display: flex;
  width: 100%;
  justify-content: flex-end;
}

.breadcrumbContainer {
  margin: 16px 0 0 32px;
}

.wrapper {
  padding: 16px;
  overflow: auto;
  background: var(--background-4);
}

.pageSpin {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  min-height: 500px;
}

.flexSpaceBetween {
  min-width: 100%;
  display: flex;
  justify-content: space-around;

  > div:first-child {
    border: 1px solid var(--color-gray-0);
    border-radius: var(--border-radius-medium);
    box-shadow: var(--box-shadow-container);
  }
}

.chapterHeader {
  display: flex;
  padding: 8px;
  justify-content: space-between;
  align-items: flex-start;
  background: var(--colorBgContainer);
}

.submissionContainer {
  display: flex;
  flex-direction: column;
  gap: calc(var(--sizeXS) * 1px);
  margin-bottom: 8px;
}

.answerContainer {
  @media print {
    div.pageBreak {
      display: block !important;
      page-break-before: avoid !important;
      break-before: avoid !important;
      page-break-inside: avoid !important;
      break-inside: avoid !important;
    }
  }
  display: flex;
  flex-flow: row wrap;
  gap: 16px;
  justify-content: space-between;
  align-items: center;
  max-width: 500px;
}

.simpleImage {
  width: 100%;
  height: 100%;
}

.questions {
  display: flex;
  flex-direction: column;
  gap: 12px;
}

.printableContainer {
  background: var(--color-white);
  padding: 24px;
}

.printPadded {
  padding-top: 16px;
  padding-bottom: 16px;
}

.pdfPageStyle {
  @media print {
    @page {
      margin-top: 0;
      margin-bottom: 0;
    }
    body {
      padding-top: 10px;
      padding-bottom: 10px;
    }
  }
}
