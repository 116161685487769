@import '../../variables';

$groupheader-height: 110px;
$sidebar-width: 246px;

.contentLayout {
  height: 100%;
  background: var(--background-4) !important;
}

.breadcrumbContainer {
  margin: 6px 19px;
}

.content {
  position: relative;
  height: calc(100vh - var(--header-height) - 112px);
}

.dataContent {
  margin: 16px;
  overflow: auto;
  height: calc(100% - #{$groupheader-height} - 32px);
}
.dataContentInReview {
  margin: 16px;
  overflow: auto;
  height: calc(100% - #{$groupheader-height} - 94px);
}
.tableContent {
  min-height: 100%;
}

.groupContent {
  @include border-floating;
  border-color: transparent;
  box-shadow: 0px 0.333333px 16px rgba(0, 0, 0, 0.01),
    0px 1.25px 5px rgba(0, 0, 0, 0.06);
  border-radius: 4px;
  background-color: white;
  min-height: $groupheader-height;
  padding: 8px 16px;
  margin: 16px;
}

.dropdownCol {
  margin-bottom: 8px;
  margin-top: 4px;
}

.editImagesButton {
  border-color: var(--color-blue-primary);
  color: var(--color-blue-primary);
}

.infoContent {
  padding: 16px;
  padding-left: 0;
  flex: 0 0 440px;

  :global {
    .ant-collapse-header {
      border-bottom: 1px solid #d9d9d9;
    }
  }
}

.stepsContent {
  padding: 5px 28px;
  :global {
    .ant-steps-item-title {
      font-size: 15px;
    }
    .ant-steps-item-wait
      > .ant-steps-item-container
      > .ant-steps-item-content
      > .ant-steps-item-title::after {
      background-color: #a9a9a9;
    }
    .ant-steps-item-process
      > .ant-steps-item-container
      > .ant-steps-item-content
      > .ant-steps-item-title::after {
      background-color: #a9a9a9;
    }
    .ant-steps-item-custom
      > .ant-steps-item-container
      > .ant-steps-item-icon
      > .ant-steps-icon {
      top: 3px;
    }
  }
}

.selectImagesContainer {
  border-color: transparent;
  position: relative;
  margin: 16px;
  padding: 16px;
  height: calc(100% - #{$groupheader-height} - 94px);
}

.approveFieldFinalStep,
.approveFieldFinalStep:hover,
.approveFieldFinalStep:active {
  background-color: var(--color-green-0);
  border-color: var(--color-green-0);
  width: 100%;
  border-radius: 16px;
  color: var(--color-white) !important;
}
.approveFieldFinalStep:disabled,
.approveFieldFinalStep:focus {
  background-color: var(--color-light-grey);
  border-color: transparent;
}
.spinnerTip {
  padding-top: 12px;
}
.spinnerText {
  margin-top: 16px;
  padding: 4px 8px;
  background: var(--color-white);
  border-radius: 2px;
  color: var(--color-blue-primary);
}
.imageSelectionContainer {
  padding: 0 0 16px 0;
  display: flex;
  justify-content: space-between;
}
.imageSelectionButtonContainer {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 26px 0;
}
.infoCollapse,
.descriptionCollapse {
  background: var(--background-4) !important;
  display: flex;
  flex-direction: column;
}
.infoCollapse {
  max-height: calc(100% - 176px);
}

.acceptanceContent,
.additionalInfoContent {
  margin: -16px;
  padding: 16px;
  background: white;
}

.acceptanceContentText {
  margin-bottom: 16px;
}

.additionalInfoContent {
  padding: 8px 0;
}

.acceptanceImageContent {
  justify-content: center;
  align-items: center;
  display: flex;
}

.acceptancePanel,
.additionalInfoPanel,
.descriptionPanel {
  background: white;
  display: flex;
  flex-direction: column;
  flex: 1 1 auto;
  min-height: 48px;
  box-shadow: 0px 0.333333px 16px rgba(0, 0, 0, 0.01),
    0px 1.25px 5px rgba(0, 0, 0, 0.06) !important;
  border-radius: 4px !important;
}

.acceptancePanel {
  margin-bottom: 16px;
}
.descriptionPanel {
  margin-bottom: 16px;
  button {
    padding: 0;
    height: 20px !important;
  }
}
.reviewedPackagesContainer {
  width: 100%;
  display: flex;
  align-items: center;
  margin: 0;
  padding: 8px 16px;
  color: var(--color-white);
  font-weight: bold;
  background: var(--color-blue-1);
  border-radius: 4px 4px 0px 0px;
}
.nextPackageBox {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  padding: 8px 16px;
  background: var(--background-4);
  border-radius: 0px 0px 4px 4px;
  margin: 0px 0px;
}
.packageBox {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  padding: 0;
  @include border-floating;
  margin: 0px 0px;
  margin-bottom: 16px;
}

.additionalInfoDataContent {
  display: flex;
}

.additionalInfoTitle {
  width: 250px;
}
