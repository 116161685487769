.chapterContainer {
  margin: 16px;
  border-bottom: 1px solid var(--color-gray-0);
  > div {
    padding: 16px;
  }
}

.sectionContainer {
  > div {
    padding: 16px;
  }
}

.fieldContainer {
  &.rootField {
    margin: 16px 0 0 16px;
  }
}
