.workItemsProgressContainer {
  display: flex;
  justify-content: space-between;
  width: 100%;
}
.flexProgressWrapper {
  display: flex;
  flex-direction: column;
  width: 100%;
}
.flexCenter {
  display: flex;
  align-items: center;
}
