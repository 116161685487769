.wrapper {
  padding: 16px !important;
  background: white;
  border: 1px solid #d9d9d9;
  box-shadow: 0px 2px 4px rgb(0 0 0 / 6%);
  border-radius: 2px;
}
.fielFormWhapper {
  padding: 0px !important;
  background: white;
}
.tabSetup {
  :global {
    .ant-tabs-nav {
      z-index: 3;
      width: 100%;
      padding: 8px 0 0 16px;
      border-bottom: 1px solid var(--shades-of-black-black-olice-90, #f2f2f3);
      background: #fff;
      box-shadow: 0px 1.25px 5px 0px rgba(0, 0, 0, 0.06),
        0px 0.3333333134651184px 16px 0px rgba(0, 0, 0, 0.01);
    }
  }
}
.tabSetupModal {
  :global {
    margin-bottom: 16px;
    .ant-tabs-nav {
      z-index: 3;
      width: 100%;
      padding: 8px 0 0 16px;
      border-bottom: 1px solid var(--shades-of-black-black-olice-90, #f2f2f3);
      background: #fff;
    }
  }
}
.editFormModal {
  :global {
    .ant-modal-content {
      padding: 20px 0;
    }
    .ant-modal-title {
      padding-left: 24px;
    }
    .ant-tabs {
      background: #fafafa;
      border-bottom: 1px solid var(--shades-of-black-black-olice-90, #f2f2f3);
      padding: 0 24px;
      margin: 8px 0 16px 0;
    }
    .ant-tabs-nav {
      z-index: 3;
      width: 100%;
      padding: 0;
      margin: 0;
      height: 100%;
      overflow: 'auto';
    }
    .ant-form {
      padding: 8px 24px;
    }
    .ant-modal-footer {
      padding: 0 24px;
    }
  }
}
.spin {
  margin: auto;
  width: 100%;
}

.formHeader {
  padding: 16px;
  background-color: white;
  border-bottom: 1px solid #d9d9d9;
}

.formItemSelect {
  min-width: 300px;
}

.contentWrapper {
  flex-direction: column;
  overflow: auto;
  height: calc(100% - 73px) !important;
  width: 100%;
}

.contentHeader {
  z-index: 3;
  background-color: #fff;
  width: stretch;
}

.contentContainer {
  display: flex;
  flex-direction: column;
  padding-top: 16px;
  padding-bottom: 16px;
  overflow: auto;
}

.siderContainer {
  background: var(--conditional-sider-background, #fff);
  box-shadow: -1px 0px 0px 0px #f0f0f0 inset;
}

.siderContentHeader {
  position: relative;
  width: 100%;
  padding: 8px 16px;
  border-bottom: 1px solid var(--shades-of-black-black-olice-90, #f2f2f3);
  border-right: 1px solid var(--shades-of-black-black-olice-90, #f2f2f3);
  background: #fff;
  box-shadow: 0px 1.25px 5px 0px rgba(0, 0, 0, 0.06),
    0px 0.3333333134651184px 16px 0px rgba(0, 0, 0, 0.01);
}

.siderContentContainer {
  margin: 8px 0;
  padding: 20px 16px;
  overflow: auto;
  width: 100%;
}

.siderFooter {
  margin-top: auto;
  display: flex;
  justify-content: flex-end;
  padding: 10px 16px;
  gap: 8px;
  width: 100%;
  box-shadow: 0px 0px 8px 0px rgba(0, 0, 0, 0.06),
    0px 0px 0px 0px rgba(0, 0, 0, 0);
}

.formGenerator {
  margin: 0 auto;
  width: 100%;
  :global {
    .ant-input[disabled] {
      color: black;
    }
    .ant-select-disabled.ant-select:not(.ant-select-customize-input)
      .ant-select-selector {
      color: black;
    }
    .ant-radio-disabled .ant-radio-inner {
      color: black;
    }
    .ant-input-number-disabled {
      color: black;
    }
    .ant-select-disabled.ant-select-multiple .ant-select-selection-item {
      color: black;
    }
    .ant-radio-disabled .ant-radio-inner::after {
      background-color: #1890ff;
    }
  }
}

.nestContent {
  border: 1px solid #d9d9d9;
  border-radius: 2px;
  padding: 8px;
  :global {
    .ant-form-item {
      margin: 8px 0;
    }
    .ant-empty-normal {
      margin: 0;
    }
  }
}

.newFieldContainer {
  max-height: 450px;
  overflow-y: auto;
}

.publishedItemForm {
  width: 650px;
}

.deleteButtonIcon {
  display: flex;
  height: 22px;
  align-items: center;
}

.centeredContent {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
}
